/* .ant-table-cell {
  border-right: solid 1px rgba(20, 20, 19, 0.2) !important;
} */

.analysis-table-row-value {
  display: block;
  margin-left: auto;
  width: max-content;
  font-weight: bold;
}

/* don't change color on row hover */
.deviation-analysis-table .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

/* don't change color if sort */
.deviation-analysis-content td.ant-table-column-sort {
  background: unset !important;
}