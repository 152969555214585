.trends-info {
  /* 150px */
  min-width: 180px;
  width: 296px;
  overflow-y: scroll;
  height: calc(100% - 71px);
  font-family: 'Roboto Condensed', sans-serif;
}

.trends-info::-webkit-scrollbar {
  background: white;
  width: 6px;
}

.trends-info::-webkit-scrollbar-thumb {
  background: #cececf;
  border-radius: 16px;
}

.trends-info-row {
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 12px;
  color: #9c9d9f;
  width: 100%;
}

.trends-info-row.active {
  cursor: pointer;
  color: #080a10;
}

.trends-info-row.active:hover {
  background: #fafafa;
}

.name-with-icon {
  display: flex;
  align-items: center;
}

.scale-icon {
  display: block;
  min-width: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ececec;
}

.scale-icon.hidden {
  height: 2px;
}

.rightText {
  text-align: right;
  padding-right: 8px;
}

.leftText {
  text-align: left;
  padding-left: 8px;
}

.widthTrend60 {
  min-width: 60%;
  width: 60%;
}
.widthTrend20 {
  min-width: 20%;
  width: 20%;
}
.widthTrend40 {
  min-width: 40%;
  width: 40%;
}
.widthTrend70 {
  min-width: 70%;
  width: 70%;
}
.widthTrend30 {
  min-width: 30%;
  width: 30%;
}

.trends-info-realtime-value {
  transition: color 1250ms ease;
}

.trends-info-realtime-value.blink {
  color: #4b7efe;
}
