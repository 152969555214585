.logout-container {
  margin-left: auto;
  position: relative;
  padding: 12px 32px 12px 24px;
  border-left: 1px solid #ECECEC;
  border-right: 1px solid #ECECEC;
  color: #9c9d9f;
}

.logout-container svg {
  cursor: pointer;
}