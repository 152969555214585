.alarm-modal {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    gap: 20px;
  }
  
  .alarm-modal-row {
    display: flex;
    color: #6B6C70;
  }
  
  .alarm-modal-title {
    font-weight: bold;
    margin-right: 5px;
  }

  .dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }