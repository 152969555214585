.deviation-analysis-container {
  width           : 100%;
  height          : 100%;
  box-sizing      : border-box;
  background-color: #fff;

  display       : flex;
  flex-direction: column;
}

.deviation-analysis-content {
  flex      : 1;
  overflow-y: hidden;
  box-sizing: border-box;
}