.switch-container {
    display: flex;
    align-items: center;
}
.switch-label-steps {
    margin-left: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}