.admin-locations-settings {
  width : 100%;
  height: 100%;

  display       : flex;
  flex-direction: column;

  background-color: #fff;
}

.admin-locations-settings-table {
  flex      : 1;
  overflow-y: hidden;
}

.admin-locations-settings-operations-column {
  display        : flex;
  align-items    : center;
  gap            : 18px;
  justify-content: flex-end;
}