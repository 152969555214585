.burger-icon {
  width: 24px;
  height: 18px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.burger-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #fff;
  opacity: 1;
  border-radius: 1px;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger-icon span:nth-child(1) {
  top: 0;
}

.burger-icon span:nth-child(2) {
  top: 6.5px;
}

.burger-icon span:nth-child(3) {
  top: 13px;
}

.burger-icon.with-menu span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
}

.burger-icon.with-menu span:nth-child(2) {
  top: 6.5px;
  opacity: 0;
  left: -60px;
}

.burger-icon.with-menu span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}
