.well-tree .menu {
	height: 100%;
	transition-duration: 0.1s;
	transition-timing-function: linear;
}

.well-tree {
	z-index: 1000;
}

.well-tree .menu-backing {
	width: 0;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	z-index: 1000;
	background-color: rgba(1, 1, 1, 0.15);
}

.well-tree.with-menu .menu-backing {
	width: 100%;
}

.well-tree .menu .menu-locations-container {
	transform: translateX(-360px);
	position: absolute;
    display: flex;
    flex-direction: column;
}

.well-tree>.menu>.menu-locations-container>div:nth-child(2) {
	height: 100%;
    overflow-y: auto;
}

.well-tree.with-menu .menu .menu-locations-container {
	transform: translateX(0px);
}

.well-tree .menu .well-container {
	transform: translateX(0);
}

.well-tree.with-menu .menu .well-container {
	transform: translateX(360px);
}

.well-data td:last-child {
  border-right: 1px solid #f0f0f0;
}