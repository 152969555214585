.range-date-time-picker-container {
  display: flex;
  gap    : 10px;
}

.range-date-time-picker-container>.ant-picker input {
  width: 135px;
}

.range-date-time-picker-header {
  background-color: #fff;
  display         : flex;
  justify-content : center;
  align-items     : center;
  position        : absolute;
  top             : -40px;
  left            : -2px;
  border-bottom   : 0px solid transparent !important;
}

.range-date-time-picker-header-time {
  background-color: #fff;
  display         : flex;
  align-items     : center;
  position        : absolute;
  top             : 0px;
  right           : 0px;
  border-bottom   : 0px solid transparent !important;
  width           : 168px;
  height          : 40px;
  text-align      : center;
}

.range-date-time-picker-popup .ant-picker-ok {
  display: none !important;
}

.range-date-time-picker-popup-now-button-hide .ant-picker-now-btn {
  display: none !important;
}

.range-date-time-picker-popup-now-button-disabled .ant-picker-now-btn {
  color         : #645f5f;
  cursor        : auto;
  pointer-events: none;
}

.ant-picker-header-view button:hover {
  color: #000;
  cursor: text;
}