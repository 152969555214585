.date-container {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  right: 0;
  padding: 24px 12px;
  width: 100%;
  align-items: center;
}

.date-buttons {
  display: flex;
  align-items: center;
}

.date-buttons .period-button:not(:last-of-type) {
  margin-right: 8px;
}

.period-button {
  cursor: pointer;
  background: #F5F6FA;
  padding: 8px;
  color: #6B6C70;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto Medium', sans-serif !important;
  box-sizing: border-box;
}

.period-button svg {
  margin-right: 12px;
}

.period-button.active {
  background: rgba(75, 126, 254, 0.16);
  color: #4B7EFE;
}

.period-button.prediction {
  margin-right: 30px;
}

.move-arrow-highlight {
  color: #4B7EFE;
}

.move-arrows {
  display: flex;
  gap: 5px;
  margin-right: 8px;
}

.move-arrows span {
  cursor: pointer;
}