.login-form {
  /* padding: 50px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 420px; */
	/* position: absolute;
	left: 25%;
	top: 50%;
	transform: translate(-100%, -50%); */
  margin: auto auto;
  margin-left: 10%;
}

.login-form > * {
	margin-bottom: 20px;
}

.login-input {
  margin-bottom: 20px;
}

.ant-notification-center {
  left: 50%;
  bottom: 50% !important;
  margin-right: 30%;
  transform: translate(-50%, -50%);
}