.breadcrumb-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4B7EFE;
}

.ant-breadcrumb span:last-child .breadcrumb-item {
  font-weight: 500 !important;
  color: #080A10 !important;
}