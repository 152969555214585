.home-container {
	padding: 12px 24px 12px 24px;
	font-size: 22px;
	color: rgba(0, 0, 0, 0.46);
	margin-top: -3px;
  border-right: 1px solid #ECECEC;
}

.home-container svg {
	cursor: pointer;
}

.home-container svg:hover {
	transition-duration: 0.3s;
	fill: #4B7EFE;
}