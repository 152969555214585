.add-priority-form {
  padding: 0 25px;
}

.select-reason-container {
  margin-bottom: 20px;
}

.add-priority-label {
  font-size: 12px;
  color: #6B6C70;
  line-height: 133%;
  margin-bottom: 6px;
  padding: 0px 4px;
}

.add-priority-modal .ant-modal-footer {
  border-top: 0;
  padding: 20px 25px 25px;
}
