.full-screen-on {
  height          : calc(100vh - 85px);
  position        : absolute;
  top             : 35px;
  background-color: #FFFFFF;
  z-index         : 999;
}

.full-screen-off {
  height: 260px;
}

.nrh-chart-container {
  position: relative;
  width   : 100%;
  height  : 100%;
  box-sizing: border-box;
}