.logout-menu-wrapper {
  display: none;
  position: absolute;
  top: auto;
  left: 0;
  padding-top: 25px;
}

.logout-menu-wrapper.open {
  display: flex;
}

.logout-menu {
  position: relative;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 4px;
  width: 183px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.logout-menu::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  border-radius: 2px 0;
  transform: rotate(45deg) translate(-50%);
  top: -2px;
}

.item {
  padding: 12px;
  border-bottom: 1px solid #ECECEC;
  cursor: pointer;
  width: 100%;
}

.item:last-child {
  border-bottom: none;
}

.item.exit {
  color: #F12A3A;
}

.item.profile {
  color: #4B7EFE;
}