.column-title {
  font-family: 'Roboto Medium', sans-serif !important;
  margin-left: -8px !important;
}

.alarm-name {
  color: #4B7EFE;
}

.alerts-table {
  /* padding: 0 25px; */
  height: 100%;
  overflow-y: inherit !important;
}

.alerts-table .ant-table {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0 !important;
  color: #080A10 !important;
}

.alerts-table .ant-table-thead > tr > th {
  padding: 12px;
}

.alerts-table::-webkit-scrollbar {
  /* background: white; */
  /* width: 0px; */
}

.hide-scrollbar
{
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.alerts-table::-webkit-scrollbar-thumb {
  background: #CECECF;
  border-radius: 0px;
}

.alerts-table .ant-table-thead > tr > th {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #000000 !important;
  opacity: 0.8 !important;
}

.alerts-table .alerts-row:nth-child(even) {
  border-radius: 2px;
  background: #F5F6FA;
}


.alerts-table .ant-table-tbody > tr > td {
  padding: 4.5px;
}

.alerts-table .ant-table-thead > tr > th {
  background-color: #fff;
}

.status-item {
  display: flex;
  align-items: center;
}

.status-item svg {
  margin-right: 12px;
}

.options-button {
  position: relative;
}

.options-menu {
  display: none;
}

.options-menu.open {
  display: flex;
  padding: 9px 16px;
  position: absolute;
  background: #FFFFFF;
  border-radius: 4px;
  right: 14px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.alarm-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}