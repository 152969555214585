.deviation-well-analysis-page {
  height    : 100%;
  overflow-y: auto;
}

.deviation-well-analysis-page .ant-table {
  font-size: 20px !important;
}

.deviation-well-analysis-page .ant-table-tbody>.ant-table-row>td {
  padding: 4.5px 16px !important;
}

.deviation-well-analysis-page-well-label {
  color        : #4B7EFE;
  border-bottom: 1px solid #4B7EFE;
}