.disable-collapse-panel-padding>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 4px 16px;
}

.mnemo-tables {
  overflow-y: auto;
  width: 100%;
  padding: 0px 25px;
  box-sizing: border-box;
}

.pad-3px {
  padding: 3px;
}