.admin-location-group-access {
  width : 100%;
  height: 100%;

  display       : flex;
  flex-direction: column;

  background-color: #fff;
}

.admin-location-group-access-select {
  display: block;
}

.admin-location-group-access-select-group {
  min-width: 250px;
  padding  : 20px 0px;
}

.admin-location-group-access-buttons {
  display    : flex;
  gap        : 10px;
  margin-left: auto;
}

.admin-locations-groups-access-table {
  flex      : 1;
  overflow-y: hidden;
}