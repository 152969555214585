.deviation-analysis-table-container {
  overflow-y: hidden;
}

.deviation-analysis-table {
  height       : 100%;
  box-sizing   : border-box;
  overflow-y   : auto;
  border-bottom: 1px solid #efefef;
}

.deviation-analysis-table .ant-table {
  font-size: 20px !important;
}

.deviation-analysis-table .ant-table-tbody>.ant-table-row>td {
  padding: 4.5px 16px !important;
}

.deviation-analysis-table .ant-table-tbody>.ant-table-row>td .ant-table-row-expand-icon {
  margin-top: 6px;
}

.deviation-analysis-table>.ant-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.deviation-analysis-chart {
  position: relative;
}

.deviation-analysis-grid-layout {
  height  : 100% !important;
  width   : 100%;
  overflow: hidden;
}