.ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ececec;
}

.custom-ant-modal .ant-modal-body {
  padding: 0;
  padding-top: 15px;
}

.ant-modal.settings .ant-modal-footer {
  padding-right: 25px;
}

.custom-ant-modal .ant-modal-close {
  top: 5px;
}
