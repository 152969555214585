.tile-background {
	background: url('assets/images/tile_background.png');
	background-size: 100%;
	height: 100%;
	width: 100%;
	margin: 0;
}

.services-list {
	text-align: center;
    display: flex;
    /* flex-wrap: wrap; */
    /* max-width: 60vw; */
    margin: auto;
    /* display: inline-block; */
    width: fit-content;
}

.services-list > div {
	/*flex: 0 0 33.333333%;*/
}