.nrh-chart-legend {
  display    : flex;
  align-items: center;
  height     : 100%;
  font-family: Roboto;
  font-size  : 12px;
}

.nrh-chart-legend>div {
  padding        : 0px 20px;
  height         : 100%;
  display        : flex;
  flex-direction : column;
  justify-content: space-around;
}