.archive-alarm-table {
  font-size : 20px;
  color     : rgba(0, 0, 0, 0.85);
  word-break: break-all;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

.archive-alarm-table::-webkit-scrollbar-thumb {
  min-height: 50px;
}

.archive-alarm-date-period-buttons {
  display        : flex;
  justify-content: center;
  align-items    : center;
  gap            : 10px;
}

.archive-alarm-date-period-buttons .period-button:not(:last-of-type) {
  margin-right: 8px;
}

.archive-alarm-date-period-buttons>div {
  cursor     : pointer;
  background : lightgrey;
  padding    : 6px 8px;
  align-items: center;
  color      : #6b6c70;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 16px;
  font-family: 'Roboto Medium', sans-serif !important;
}

.archive-alarm-date-period-buttons>div svg {
  margin-right: 12px;
}

.archive-alarm-date-period-buttons>div.active {
  background: rgba(75, 126, 254, 0.16);
  color     : #4b7efe;
}

.archive-alarm-xlsx-icon {
  width  : 28px;
  height : 28px;
  opacity: 0.8;
  cursor : pointer;
}