.dashboard-container {
  height: 100%;
  display: flex;
}

.dashboard-grid-layout {
  height: calc(100% + 10px) !important;
  width: 100%;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -10px;
  min-width: 750px;
  /*display: flex; 
  flex-flow: column; 
  /*height: 100%;*/
}

.react-grid-item {
  background: #fff;
  width: 100% !important;
  overflow: hidden;
}

.react-grid-item.full-screen {
  position: fixed !important;
  width: 100% !important;
  height: calc(100% - 48px) !important;
  transform: none !important;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
}

.right {
  width: 100%;
  max-width: 380px;
  background: #fff;
}

.react-grid-item.react-grid-placeholder {
  background-color: #f5f6fa !important;
}

.mnemo-label {
  position: absolute;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
}

.mnemo-label>div {
  display: inline-block;
  min-width: 50px;
  width: max-content;
  font-size: small;
  text-align: center;
  padding: 5px;
}

.mnemo-label>div:nth-child(1) {
  color: #666;
}

.mnemo-label>div:nth-child(2) {
  color: rgba(75, 126, 254, 1);
}

.mnemo-label>div:last-child {
  width: 20px;
  padding: 2px;
}

.fancy-scroll::-webkit-scrollbar-thumb {
  background: #cececf;
  border-radius: 16px;
}

.fancy-scroll::-webkit-scrollbar {
  background: white;
  width: 6px;
}