.date-report-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.date-report-buttons .period-button:not(:last-of-type) {
  margin-right: 8px;
}

.date-report-buttons > div {
  cursor: pointer;
  background: lightgrey;
  padding: 12px 20px;
  align-items: center;
  color: #6b6c70;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto Medium', sans-serif !important;
}

.date-report-buttons > div svg {
  margin-right: 12px;
}

.date-report-buttons > div.active {
  background: rgba(75, 126, 254, 0.16);
  color: #4b7efe;
}

.date-report-download-button {
  line-height: 16px;
  height: 100%;
  padding: 11px 20px 13px;
}