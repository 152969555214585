body {
  color: #080A10;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 18px;
  background: #f5f6fa;
}

#root {
  height: 100%;
  padding-top: 48px;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"),
    url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"),
    url("../fonts/RobotoCondensed/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"),
    url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
