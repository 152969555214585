.delete-priority-modal {
  padding: 0 25px;
}

.delete-warning {
  font-size: 14px;
  color: #F12A3A;
  line-height: 143%;
  margin-bottom: 16px;
}
