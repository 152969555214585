.well-treemap-layout-buttons {
  display    : flex;
  align-items: center;

  gap: 16px;

  margin-left: 24px;
}

.well-treemap-layout-buttons>button[data-active=true] {
  background: rgba(75, 126, 254, 0.16);
  color     : #4b7efe;
}

.well-treemap-layout-legend-container {
  display    : flex;
  align-items: center;

  gap: 8px;

  margin-left : auto;
  margin-right: auto;
}

.well-treemap-layout-legend-container > span {
  font-weight: 600;
}

.well-treemap-layout-legend {
  display    : flex;
  align-items: center;

  gap: 2px;

  background-color: black;

  padding: 4px;
}

.well-treemap-layout-legend>div {
  display        : flex;
  align-items    : center;
  justify-content: center;

  padding: 0px 8px;

  min-width: 32px;
}

.well-treemap-layout-legend>div>span {
  color: #ffffff;
}