.deviation-reason-table .diagonal-header-cell {
  position: relative;

  width : 250px;
  height: 100px;

  padding: 0px !important;

  text-align: start !important;
}

.diagonal-header-cell .top {
  position: absolute;
  top     : 4px;
  right   : 10px;
}

.diagonal-header-cell .bottom {
  position: absolute;
  bottom  : 1px;
  left    : 4px;
}

.diagonal-header-cell .diagonal-line {
  position: absolute;

  display      : block;
  border-bottom: 1px solid #f0f0f0;
  width        : 270px;
  transform    : translateX(-10px) rotate(21.8deg);
}