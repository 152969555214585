.nrh-chart-axis-labels {
  position   : absolute;
  font-family: "Roboto";
  font-size  : 12px;
  font-weight: bold;
  color      : #080A10;
  z-index    : 10;
}

.nrh-chart-axis-labels>div {
  position: relative;
}