.menu-locations-container {
  position                  : fixed;
  height                    : 100%;
  width                     : 360px;
  background-color          : #5B6378;
  z-index                   : 100000;
  transition-duration       : 0.2s;
  transition-timing-function: linear;
}

.highlighted,
.highlighted>span,
.highlighted rect {
  color: #1890ff !important;
  fill : #1890ff !important;
}

.menu-locations-title {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding: 20px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  color: rgba(255, 255, 255, 0.478);
}

.menu-title {
  color      : #fff;
  font-size  : 16px;
  font-weight: 600;
  font-family: "Roboto Medium";
}

.ant-collapse.locations-data {
  background-color: #5B6378;
}

.ant-collapse.locations-data .ant-collapse-item .ant-collapse-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.ant-collapse-icon-position-right.locations-data>.ant-collapse-item>.ant-collapse-header {
  padding  : 20px 25px;
  font-size: 16px;
  color    : #fff;
}

.ant-collapse.locations-data>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  top        : unset;
  right      : 25px;
  padding-top: 0;
}

.ant-collapse.locations-data .ant-collapse-content>.ant-collapse-content-box {
  padding     : 0px;
  padding-left: 15px;
}

.sublocation-title-container {
  display    : flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.478);
}

.sublocation-title {
  color: #fff;
  margin-left: 12px;
  /* color: rgba(255, 255, 255, 0.478); */
}

.ant-collapse-ghost.sublocations-data > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.object-item {
  cursor       : pointer;
  padding      : 10px 15px;
  position     : relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.object-item-title {
  margin-left: 12px;
  color      : rgba(255, 255, 255, 0.478);
}

.object-indicator {
  top             : 0;
  left            : 0;
  width           : 4px;
  position        : absolute;
  background-color: #4B7EFE;
}

.object-item.active {
  border-top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), #5B6378;
}

.object-item.active .object-item-title {
  color      : #fff;
  font-weight: 600;
}

.object-item.active .object-indicator {
  height: 100%;
}

.object-item.disabled {
  cursor: not-allowed;
}