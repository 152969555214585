.admin-users-settings {
  width : 100%;
  height: 100%;

  display       : flex;
  flex-direction: column;

  background-color: #fff;
}

.editable-row:nth-child(2n) {
  background-color: rgba(1, 1, 100, 0.03) !important;
}

.admin-users-settings>.ant-table-wrapper {
  flex: 1;
}

.admin-users-settings-operations-column {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.admin-users-settings-table {
  flex      : 1;
  overflow-y: hidden;
}