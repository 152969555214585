.well-treemap-container {
  width   : 100%;
  height  : 100%;
  overflow: hidden;
  position: relative;
}

.well-treemap-tooltip-value {
  font-weight: 600;
}

.well-treemap-tooltip-unit {
  margin-left: 2px;
}
