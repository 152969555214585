.trends-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 25px;
    border-bottom: 1px solid #ececec;
}

.trends-header-content svg {
    cursor: pointer;
}

.trends-icons-container {
    display: flex;
    align-items: center;
}

.trends-icons-container svg:not(:last-of-type) {
    margin-right: 18px;
}