.admin-groups-settings {
  width : 100%;
  height: 100%;

  display       : flex;
  flex-direction: column;

  background-color: #fff;
}

.admin-groups-settings>.ant-table-wrapper {
  flex: 1;
}

.admin-groups-settings-operations-column {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.admin-groups-settings-table {
  flex      : 1;
  overflow-y: hidden;
}