.switch-container {
  display: flex;
  align-items: center;
}

.switch-label {
  margin-left: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6B6C70;
}