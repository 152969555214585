.tables {
  display: flex;
}

.trends-setting-table {
  padding: 0 25px;
  height: 350px;
  overflow-y: scroll;
}

.trends-setting-table::-webkit-scrollbar {
  background: white;
  width: 6px;
}

.trends-setting-table::-webkit-scrollbar-thumb {
  background: #CECECF;
  border-radius: 16px;
}

.trends-setting-table .trends-setting-row:nth-child(even) {
  border-radius: 2px;
  background: #F5F6FA;
}

.trends-setting-table .ant-table-thead > tr > th {
  background-color: #fff;
}

.title {
  font-weight: bold;
}

.descriptor {
  font-size: 14px;
  color: #6B6C70;
}

.field-with-description {
  display: flex;
  align-items: center;
}

.field-with-description .descriptor {
  margin-right: 4px;
  margin-left: 8px;
}

.field-with-description input::-webkit-outer-spin-button,
.field-with-description input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.trends-modal .modal-title {
  margin-bottom: 0;
}

.trends-templates-settings {
  display: inline-block;
}

.tables ul>li {
  width: 100%;
}

.trends-count-container {
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  right: 0;
  top: 84%;
  padding: 24px;
  width: 100%;
}

.block {
  /* display: block;
  width: 500px;
  border: 1px solid black;
  background-color: #04AA6D;
  color: white;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center; */
}

.block .ant-btn {
  width: 200px;
}

.block .ant-dropdown-trigger {
  width: 30px
}
