.right-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.breadcrumbs-container {
  margin-left: 25px;
}

.burger-container {
  height: 100%;
  width: 72px;
  background: #5B6378;
  display: flex;
  align-items: center;
  justify-content: center;
}

.burger-container svg {
  cursor: pointer;
}

.engineer-screen-header-button {
  margin-left: auto;
  height: 100%;
  border-left : 1px solid #ECECEC;
}