.ant-form-container {
  padding-right: 40px;
}

.ant-form-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 4px;
}

.ant-form-input {
  width: 20%;
}
