.custom-select.ant-select {
  width: 100%;
}

.custom-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #F5F6FA
}

.custom-select .ant-select-selection-placeholder {
  font-size: 14px;
  color: #6B6C70;
}

.custom-select.ant-select-disabled .ant-select-selection-placeholder {
  color: #9C9D9F;
}

.custom-select .ant-select-arrow {
  width: 24px;
  height: 24px;
  margin-top: 0;
  top: 4px;
  right: 9px;
}
