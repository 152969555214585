.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 25px;
  border-bottom: 1px solid #ececec;
}

.header-content svg {
  cursor: pointer;
}

.header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #080a10;
}

.icons-container {
  display: flex;
  align-items: center;
}

.icons-container svg:not(:last-of-type) {
  margin-right: 18px;
}
