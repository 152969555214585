.engineer-screen-in-header-container {
  display    : flex;
  align-items: center;
  gap        : 8px;
  height     : 100%;
  padding    : 0px 12px;
}

.engineer-screen-green-circle {
  width           : 16px;
  height          : 16px;
  border-radius   : 50%;
  background-color: #008257;
}

.engineer-screen-red-circle {
  width           : 16px;
  height          : 16px;
  border-radius   : 50%;
  background-color: #f12a3a;
}

.engineer-screen-gray-circle {
  width           : 16px;
  height          : 16px;
  border-radius   : 50%;
  background-color: #a0a0a0;
}