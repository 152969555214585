.DataTooltipContainer {
  position: absolute;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  background-color: rgb(90, 100, 120);
  padding: 5px 15px 5px 10px;
  border-radius: 5px;

  font-family: Roboto;
  font-size: 13px;
  color: white;
  min-width: 500px;
  max-width: 500px;
}

.DataTooltipTime {
  width: max-content;
  margin-left: 8px;
}

.DataTooltipPoint {
  display: flex;
  align-items: center;
  padding: 4px;
  border-bottom: 2px solid rgb(70, 80, 100);
}

.DataTooltipPoint:last-child {
  border-bottom: none;
}

.DataTooltipPointLabel {
  width: max-content;
  margin-right: auto;
  padding-right: 10px;
}

.DataTooltipPointValue {
  display: flex;
  background-color: rgb(70, 80, 100);
  min-width: 80px;
}

.DataTooltipPointValue div {
  display: flex;
  width: 5px;
}

.DataTooltipPointValue span {
  padding: 2px;
  padding-left: 6px;
  font-weight: 600;
}
