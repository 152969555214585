header {
  height: 48px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 1000000000000;
}

.main-layout {
  height: 100%;
  position: relative;
}