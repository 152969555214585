.log-table-container1 {
    height        : 100%;
    overflow-y    : auto;
    display       : flex;
    flex-direction: column;
  }
  
  .log-table-container12 .ant-table {
    font-size: 20px !important;
  }
  
  .log-table-container1 .ant-table .ant-table-header .ant-table-cell:last-child {
    padding: 8px !important;
  }
  
  .log-table12 .ant-table-tbody>.ant-table-row>td {
    padding: 4.5px 16px !important;
  }
  
  .alarm-page-well-label {
    color        : #4B7EFE;
    border-bottom: 1px solid #4B7EFE;
  }
  
  /* don't change color on row hover */
  .log-table-container12 .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: unset;
  }
  
  /* don't change color if sort */
  .log-table-container12 td.ant-table-column-sort {
    background: #fff !important;
  }
  
  .active-alarms-table-blinking {
    animation: blinking 1.5s infinite;
  }
  
  @keyframes blinking {
  
    0%,
    100% {}
  
    50% {
      background-color: #fff;
    }
  }

  .log-history-button-container {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0 50px;
    gap: 20px;
  }
  
  .log-history-button {
    width: max-content;
    cursor: pointer;
    background: #f5f6fa;
    padding: 12px 20px;
    align-items: center;
    color: #6b6c70;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  
  .log-history-button.disabled {
    background: rgb(250, 249, 249);
    color: rgb(219, 207, 207);
  }
  
  .log-history-button.active {
    background: rgba(75, 126, 254, 0.16);
    color: #4b7efe;
  }

  .log-history-footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0px;
    padding: 0px 20px;
    overflow-x: auto;
  }