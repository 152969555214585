.nrh-chart-tooltip {
  position        : absolute;
  z-index         : 1000000;
  pointer-events  : none;
  background-color: rgb(90, 100, 120);
  padding         : 5px;
  border-radius   : 5px;

  font-family: Roboto;
  font-size  : 13px;
  color      : white;
  min-width  : 50px;
  max-width  : 200px;
  width      : max-content;
}