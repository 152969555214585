.deviation-analysis-chart-header {
  width          : 100%;
  height         : 60px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  padding        : 0px 50px;
}

.deviation-analysis-chart-header>div {
  width      : max-content;
  display    : flex;
  align-items: center;
  margin     : 0px 20px;
  padding    : 5px;
  cursor     : pointer;
}