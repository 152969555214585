.trends-container {
  display: flex;
  height: 100%;
  max-width: 100%;
}

.switcher-target-mode {
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-left: 10px;
}

.switcher-target-mode span {
  color: #6b6c70;
  margin-right: 10px;
}

.chart-wrapper {
  flex: 1;
  display: flex;
  height: calc(100% - 61px);
}

.chart-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 600px;
}

.chart-content .chart-container .canvas-charts:not(:last-of-type) {
  border-bottom: 7px solid #ececec;
}

.open-bar {
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ececec;
  width: 13px;
  cursor: pointer;
}

.open-bar svg {
  cursor: pointer;
}
