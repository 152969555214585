.mnemo-table {
  position: absolute;
  overflow: hidden;
}

.mnemo-table-header {
  padding: 8px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mnemo-table-parameters {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.mnemo-table-parameters>.mnemo-table-wrapper {
  display: flex;
  gap: 20px;
}

.mnemo-table-wrapper>.mnemo-table-value {
  display: flex;
  gap: 8px;
}

.mnemo-table-parameters .ant-table-tbody>.ant-table-row>.ant-table-cell:last-child {
  width: 0;
  min-width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
}

.mnemo-table-parameters .ant-table-tbody>tr>td {
  border-bottom: none;
}

.mnemo-table-parameters .ant-table-tbody>tr>td>span {
  color: inherit !important;
}