.alarm-table-container {
  height        : 100%;
  overflow-y    : auto;
  display       : flex;
  flex-direction: column;
}

.alarm-table-container .ant-table {
  font-size: 20px !important;
}

.alarm-table-container .ant-table .ant-table-header .ant-table-cell:last-child {
  padding: 8px !important;
}

.alarm-table .ant-table-tbody>.ant-table-row>td {
  padding: 4.5px 16px !important;
}

.alarm-page-well-label {
  color        : #4B7EFE;
  border-bottom: 1px solid #4B7EFE;
}

/* don't change color on row hover */
.alarm-table-container .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: unset;
}

/* don't change color if sort */
.alarm-table-container td.ant-table-column-sort {
  background: #fff !important;
}

.active-alarms-table-blinking {
  animation: blinking 1.5s infinite;
}

@keyframes blinking {

  0%,
  100% {}

  50% {
    background-color: #fff;
  }
}