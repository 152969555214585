.info-content {
  display: flex;
  flex-direction: column;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #080a10;
  height: 100%;
  overflow-y: auto;
}

.info-division {
  border-bottom: 1px solid #ececec;
  margin: 15px 24px 5px 24px;
  display: flex;
  flex-direction: column;
}

.info-division:last-child {
  overflow-y: hidden;
}

.info-division-fullScreen {
  position: absolute;
  background-color: #FFFFFF;
  padding-left: 2%;
  z-index: 5;
}

.info-division.dynamic {
  height: calc(100% - 189px);
}

.info-division.dynamic::-webkit-scrollbar {
  background: white;
  width: 6px;
}

.info-division.dynamic::-webkit-scrollbar-thumb {
  background: #cececf;
  border-radius: 16px;
}

.info-division:last-child {
  border-bottom: none;
}

.info-division-title {
  margin-bottom: 8px;
  font-family: "Roboto Medium", sans-serif;
  font-size: 16px;
}

.info-division .info-division-row:nth-child(even) {
  border-radius: 2px;
  background: #f5f6fa;
}

.info-division-row {
  width: 100%;
  display: flex;
  padding: 6px 0;
}

.info-division-row-bg {
  width: 100%;
  display: flex;
  padding: 6px 0;
}

.info-division-item {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding: 0 12px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.width60 {
  width: 60%;
}

.width20 {
  width: 20%;
}

.width30 {
  width: 30%;
}

.tooltip-text {
  font-size: 12px;
  font-weight: 400;
}