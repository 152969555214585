.mnemoscheme-table-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  border-bottom: 1px solid #e9e6e5;
}

.mnemoscheme-table-list-item:last-child {
  border-bottom: 0px;
}