.mnemo-area-wrapper {
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;
  background: #FFFFFF;

  display: flex;
  flex-direction: column;

  flex-shrink: 0;
}

.mnemo-area-wrapper .mnemo-area-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 35px;
  position: relative;
}

.mnemo-area-wrapper .mnemo-img-wrapper {
  position: relative;
  overflow: auto;
  flex: 1;
}

.mnemo-area-wrapper .mnemo-area-header .mnemo-switch {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.mnemo-img-wrapper .mnemo-img {
  position: absolute;
}

