.treemap-tooltip {
  position: absolute;

  width: 300px;

  background-color: rgb(90, 100, 120);

  padding      : 5px 15px 5px 10px;
  border-radius: 5px;

  font-family: Roboto;
  font-size  : 13px;
  color      : white;

  transition: opacity 25ms ease-in;
}

.treemap-tooltip[data-visible=false] {
  opacity: 0 !important;
}

.treemap-tooltip-label {
  margin-bottom: 8px;
}