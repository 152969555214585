.column-title {
  font-family: 'Roboto Medium', sans-serif !important;
}

.alarm-name {
  color: #4b7efe;
}

.ant-table-content {
  overflow: clip !important;
}

.ant-menu-overflow-item {
  flex-grow: 1 !important;
}

/* .alerts-table {
    padding: 0 25px;
    height: 100%;
    overflow-y: scroll;
  }
  
  .alerts-table .ant-table {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0 !important;
    color: #080A10 !important;
  } */

/* .alerts-table::-webkit-scrollbar {
    background: white;
    width: 6px;
  }
  
  .alerts-table::-webkit-scrollbar-thumb {
    background: #CECECF;
    border-radius: 16px;
  } */

/* .alerts-table .ant-table-thead > tr > th {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500 !important;
    color: #000000 !important;
    opacity: 0.8 !important;
  }
  
  .alerts-table .alerts-row:nth-child(even) {
    border-radius: 2px;
    background: #F5F6FA;
  }
  
  .alerts-table .ant-table-thead > tr > th {
    background-color: #fff;
  }
  
  .status-item {
    display: flex;
    align-items: center;
  }
  
  .status-item svg {
    margin-right: 0px;
  }
  
  .options-button {
    position: relative;
  }
  
  .options-menu {
    display: none;
  }
  
  .options-menu.open {
    display: flex;
    padding: 9px 16px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 4px;
    right: 14px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
  } */

.alarm-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.menu-item-style::after {
  display: block !important;
  content: "" !important;
  border-bottom: solid 2px #1890ff !important;
  transform: scaleX(0) !important;
  transition: transform 250ms ease-in-out !important;
  width: 100%;
  margin-left: -20px;
}

.ant-menu-item-selected::after,
.menu-item-style:hover::after {
  transform: scaleX(1) !important;
}

.menu-item-style:hover {
  z-index: 10001;
  background-color: #fff !important;
}

.ant-menu-item-selected {
  z-index: 10000;
  background-color: #e2eaff !important;
}

/* .site-layout-content {
    min-height: 280px;
    padding: 48px;
    background: #fff;
  }
  .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  [data-theme="dark"] .site-layout-content {
    background: #ffffff;
  } */

.ant-menu-item {
  background-color: #f5f6fa;
}

.ant-menu-item-selected {
  background-color: rgba(75, 126, 254, 0.16);
}

.menu-item-style {
  border-bottom: none !important;
  width: calc(10% - 10px) !important;
  text-align: center !important;
  margin: 8px 4px 8px 4px !important;
  font-family: 'Roboto Medium', sans-serif !important;
  color: #6b6c70;
}
