.alarm-deviation-analysis-reasons .data-text {
  font-family: 'Roboto Condensed', sans-serif;
}

.alarm-deviation-analysis-reasons>div:not(:first-child) {
  border-top: 2px solid #f0f0f0;
  padding   : 6px 0px;
}

.alarm-deviation-analysis-reasons>div:first-child {
  border-top: 2px solid #f0f0f0;
}

.alarm-deviation-analysis-reasons .ant-collapse-header {
  padding: 8px 0px !important;
}

.alarm-deviation-analysis-reasons .ant-collapse-content-box {
  padding: 0px !important;
}

.alarm-deviation-analysis-reasons .ant-collapse-expand-icon {
  position: absolute;
  left    : -15px;
}