.workParams-info {
  flex: 1;
  min-width: 150px;
  width: 296px;
  overflow-y: scroll;
  height: calc(100% - 71px);
  width: 100%;
}

.workParams-info::-webkit-scrollbar {
  background: white;
  width: 6px;
}

.workParams-info::-webkit-scrollbar-thumb {
  background: #cececf;
  border-radius: 16px;
}

.workParams-info-row {
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  color: #080a10;
  width: 100%;
}

.workParams-info .workParams-info-row:nth-child(odd) {
  background: #f5f6fa;
}

.workParams-info-row.active {
  cursor: pointer;
  color: #080a10;
}

.workParams-info-row.active:hover {
  background: #fafafa;
}

/* .name-with-icon {
  display: flex;
  align-items: center;
} */

/* .scale-icon {
  display: block;
  width: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ececec;
}

.scale-icon.hidden {
  height: 2px;
} */

.rightText {
  text-align: right;
  padding-right: 8px;
}

.leftText {
  text-align: left;
  padding-left: 8px;
}

.widthWorkParam60 {
  min-width: 60%;
  width: 60%;
}

.widthWorkParam20 {
  min-width: 20%;
  width: 20%;
}

.widthWorkParam40 {
  min-width: 40%;
  width: 40%;
}

.widthWorkParam70 {
  min-width: 70%;
  width: 70%;
}

.widthWorkParam30 {
  min-width: 30%;
  width: 30%;
}