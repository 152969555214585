.engineer-screen-table {
  white-space: pre-wrap;
}

.engineer-screen-table-indicator-column {
  padding: 8px !important;
}

/* don't change color on row hover */
.engineer-screen-table .ant-table-tbody>tr.ant-table-row-level-0:hover>td {
  background: unset;
}

/* disable cursor pointer */
.engineer-screen-table .ant-table-row {
  cursor: default;
}
