.well-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 15px;
  border-bottom: 1px solid #ECECEC;
}

.well-nav-container {
  height: 24px;
}

.well-nav-container svg {
  cursor: pointer;
}

.well-nav-container svg:not(:last-of-type) {
  margin-right: 30px;
}

svg.alert-icon.disabled {
  cursor: not-allowed;
}

svg.alert-icon.disabled:active {
  pointer-events: none;
}

svg.alert-icon.available {
  cursor: pointer;
  pointer-events: all;
}

svg.alert-icon.delete-priority-icon g {
  stroke: red;
}

.minimized-button {
  height: 100%;
  display: flex;
  align-items: center;
}

.minimized-button svg {
  cursor: pointer;
}
