.deviation-analysis-button-container {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0 50px;
  gap: 20px;
}

.deviation-analysis-button {
  width: max-content;
  cursor: pointer;
  background: #f5f6fa;
  padding: 12px 20px;
  align-items: center;
  color: #6b6c70;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.deviation-analysis-button.disabled {
  background: rgb(250, 249, 249);
  color: rgb(219, 207, 207);
}

.deviation-analysis-button.active {
  background: rgba(75, 126, 254, 0.16);
  color: #4b7efe;
}
