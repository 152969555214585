.log-uploading-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.log-header {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  padding: 15px;
  font-weight: bold;
  border-right: 1px solid #f0f0f0;
}

.log-date-column {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f0f0f0;
}

.log-dates {
  flex: 1;
  overflow-y: auto;
}

.log-date {
  padding: 12px;
  cursor: default;
}

.log-date-active {
  background-color: #e2eaff !important;
  border-right: 3px solid #1890ff;
}

.log-date:hover {
  background-color: #fafafa;
}

.log-description-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.log-description {
  flex: 1;
  padding: 12px;
  word-wrap: break-word;
  overflow-y: auto;
  white-space: pre-wrap;
}