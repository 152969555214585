.settings-modal {
  padding: 0 25px 25px;
  z-index: 100000000;
}

.settings-modal-divergence {
  padding: 0 25px 25px;
  z-index: 100000000;
  display: flex;
  justify-content: center;
}

.settings-modal-title {
  display: flex;
  align-items: center;
}

.settings-modal-title svg {
  cursor: pointer;
  margin-right: 15px;
}

.choose-settings-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.choose-settings-container:not(:last-of-type) {
  border-bottom: 1px solid #ececec;
}

.choose-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #6b6c70;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.checkbox-wrapper .ant-checkbox-wrapper {
  margin-bottom: 15px;
}

.checkbox-wrapper .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #cececf;
}

.checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #6b6c70;
  border-top: 0;
  border-left: 0;
}

.switch-wrapper {
  display: flex;
  flex-direction: column;
}

.switch-wrapper .switch-container:not(:last-of-type) {
  margin-bottom: 16px;
}

.trendModalScroll {
  overflow: auto;
  overflow-x: hidden;
}
