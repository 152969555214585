.well-container {
  max-width: 360px;
  min-width: 248px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  left: 0;
  z-index: 100000;
  position: relative;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.instant-transform {
  transition-duration: 0ms !important;
}

.well-container.minimized {
  max-width: 72px;
  min-width: 72px;
}

.ant-table-wrapper::-webkit-scrollbar-thumb {
  background: #cececf;
  border-radius: 16px;
}

.ant-table-wrapper::-webkit-scrollbar {
  background: white;
  width: 6px;
}

.ant-table-thead > tr > th::before {
  display: none;
}

.ant-table-wrapper.well-data .ant-table-thead > tr > th,
.ant-table-wrapper.well-data .ant-table-tbody > tr > td,
.ant-table-wrapper.well-data .ant-table tfoot > tr > th,
.ant-table-wrapper.well-data .ant-table tfoot > tr > td {
  padding: 0;
}

.well-data {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.well-data .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 999;
}

.well-data .ant-table-thead > tr > th {
  background-color: #ffffff;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-row.selected {
  background-color: #ececec;
  position: relative;
}

.well-number-container {
  display: flex;
  padding: 10px;
  padding-left: 7px;
  align-items: center;
}

.ant-table-row.selected .selected-flag {
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #4b7efe;
}

.setted-reason {
  position: absolute;
}

.well-number {
  color: #080a10;
  /*margin-left: 9px;*/
}

.tooltip-title-container {
  margin-bottom: -3px;
}

.title-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
}

.title-normal {
  font-size: 12px;
  font-weight: 400;
  line-height: 133%;
}

.well-name-container {
  /* display: flex; */
  align-items: center;
}

.well-status-container {
  display: flex;
  align-items: center;
}

.well-name-container.minimized {
  justify-content: space-between;
  width: 100%;
}

.light-green {
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #80c1ac;
}

.blue-circle {
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #4b7efe;
}

.brown-circle {
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #ca9b9b;
}

.light-green.minimized {
  margin-right: 7px;
}

.green-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #008257;
}

.red-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #f12a3a;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.yellow-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fec400;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.red-yellow-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: linear-gradient(90deg, #f12a3a, #fec400);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.well-footer-container {
  width: 100%;
  margin-top: auto;
  padding: 5px 30px;
  border-top: 1px solid #ececec;
}

.total-wells {
  font-size: 12px;
  color: #9c9d9f;
  margin-right: 5px;
}

.total-count {
  font-size: 12px;
  color: #6b6c70;
}

.well-tree-table-title {
  margin: 8.4px 0;
  font-size: 12px;
  line-height: 14px;
  color: #6b6c70;
}

.well-tree-table-title.first {
  margin-left: 10px;
  margin-right: 11px;
}

.well-tree-table-title.name {
  margin-right: 28px;
}

.well-tree-table-title.info {
  margin-right: 10px;
}

.well-tree-table-title.params {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f6fa;
  border-radius: 3px;
  width: 22px;
  height: 20px;
  margin-right: 4px;
}

.well-text {
  color: #080a10;
  min-width: 50px;
  max-width: 80px;
  padding-right: 6px;
}

.minimized-row-container {
  display: flex;
  height: 42px;
}

.minimized-name-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.minimized-name-container svg {
  margin-right: 4px;
}

.minimized-container {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
}

.minimized-info {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: #9c9d9f;
}
